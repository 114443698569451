define("discourse/plugins/waze-forms/discourse/components/waze-copy-logs-button", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/controller", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _controller, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    ...attributes
    @action={{this.openLogsUrl}}
    @icon="w-doc"
    @title="js.waze.forms.open_logs_button.description"
  />
  */
  {
    "id": "TYn1EmFR",
    "block": "[[[8,[39,0],[[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"openLogsUrl\"]],\"w-doc\",\"js.waze.forms.open_logs_button.description\"]],null]],[\"&attrs\"],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/waze-forms/discourse/components/waze-copy-logs-button.hbs",
    "isStrictMode": false
  });
  function padStart(number) {
    return number.toString().padStart(2, "0");
  }
  function formatDate(date) {
    return `${date.getFullYear()}-${padStart(date.getMonth() + 1)}-${padStart(date.getDate())}`;
  }
  function buildLogsUrl(baseUrl, username, postCreationDate) {
    const postDate = new Date(postCreationDate);
    const dayBeforePostDate = new Date(postDate);
    dayBeforePostDate.setDate(postDate.getDate() - 1);
    const dayAfterPostDate = new Date(postDate);
    dayAfterPostDate.setDate(postDate.getDate() + 1);
    const logsUrl = new URL(baseUrl);
    logsUrl.searchParams.append("start", formatDate(dayBeforePostDate));
    logsUrl.searchParams.append("end", formatDate(dayAfterPostDate));
    logsUrl.searchParams.append("username", username);
    return logsUrl;
  }
  class WazeCopyLogsButton extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    openLogsUrl() {
      const logsBaseUrl = this.siteSettings.waze_logs_and_stats_domain;
      const firstPostInTopic = this.topic.model.postStream.posts.find(post => post.firstPost);
      const logsUrl = buildLogsUrl(logsBaseUrl, firstPostInTopic.username, firstPostInTopic.created_at);
      window.open(logsUrl);
    }
    static #_3 = (() => dt7948.n(this.prototype, "openLogsUrl", [_object.action]))();
  }
  _exports.default = WazeCopyLogsButton;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WazeCopyLogsButton);
});